import React, {useContext} from "react";

import {Link} from "gatsby";

import {authContext} from "../contexts/authContext";

import Layout from "../components/Layout";
import Section from "../components/Section";
import Promo from "../components/Promo";
import LoginForm from "../components/LoginForm.js";

import appGoogleImg from "../images/appGoogle.jpg";
import appStoreImg from "../images/appStore.jpg";

const Index = (props) => {
	const auth = useContext(authContext);

	return (
		<Layout location={props.location}>
			<Section title={"Tere tulemast Dr Simeonsi dieedi tugikeskusesse!"}>
				<div className={"flex flex-col text-sm max-w-4xl leading-5"}>
					<p className={"mb-6 mt-4 text-center"}>
						Tugikeskus on hea abivahend Dr Simeonsi dieedi abil kaalu langetamisel.
					</p>
					<p className={"mb-6 text-center"}>Siit leiad programmi läbimiseks vajaliku <Link
						to="/app/guide" className="text-green">juhendi</Link> ja
						dieedietappe
						tutvustavad <Link to="/app/guide" className="text-green">lühivideod</Link>, alla laadimiseks
						ja
						printimiseks <Link to="/app/resources" className="text-green">kasulikud materjalid</Link> ja
						kaalulangetamise progressi jälgimiseks
						vajaliku <Link to="/app/diary" className="text-green">päeviku</Link>.</p>
					<p className={"mb-6 text-center"}>
						Igale kaalulangetajale on määratud isiklik dieedikonsultant, kes on meili kaugusel.
						Soovi korral saad saata oma konsultandile <Link to="/app/feedback"
																		className="text-green">teate</Link> ka
						läbi tugikeskuse.
					</p>
					<p className={"text-center"}>
						Oleme Sinu jaoks iga päev olemas. Edukat dieediringi!
					</p>
				</div>
			</Section>
			{auth.isLoggedIn ? (
				""
			) : (
				<Section title={" "}>
					<h1>Logi sisse</h1>
					<LoginForm/>
					<div
						className="flex flex-col md:flex-row items-center justify-between border-t pt-8 mt-10 w-full text-sm">
						<p className="max-w-sm text-center md:text-left">
							Dr Simeonsi dieedi tugikeskus mobiilirakendus on saadaval siin:
						</p>
						<div className="flex">
							<a
								target={"_blank"}
								rel="noreferrer"
								href={
									"https://play.google.com/store/apps/details?id=com.shternconsulting.simeonsdiet.app"
								}
								className="max-w-2xl w-full h-auto mr-3"
							>
								<img src={appGoogleImg} alt="GooglePlay"/>
							</a>
							<a
								target={"_blank"}
								rel="noreferrer"
								href={
									"https://apps.apple.com/us/app/dr-simeonsi-dieedi-tugikeskus/id1435077850?ls=1"
								}
								className="max-w-2xl w-full h-auto m-0"
							>
								<img src={appStoreImg} alt="AppStore"/>
							</a>
						</div>
					</div>
				</Section>
			)}
			<Promo/>
		</Layout>
	);
};

export default Index
